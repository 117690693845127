// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-afspraak-maken-js": () => import("/opt/build/repo/src/pages/afspraak-maken.js" /* webpackChunkName: "component---src-pages-afspraak-maken-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclamer-js": () => import("/opt/build/repo/src/pages/disclamer.js" /* webpackChunkName: "component---src-pages-disclamer-js" */),
  "component---src-pages-gratis-offerte-js": () => import("/opt/build/repo/src/pages/gratis-offerte.js" /* webpackChunkName: "component---src-pages-gratis-offerte-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klantenreacties-js": () => import("/opt/build/repo/src/pages/klantenreacties.js" /* webpackChunkName: "component---src-pages-klantenreacties-js" */),
  "component---src-pages-onze-diensten-js": () => import("/opt/build/repo/src/pages/onze-diensten.js" /* webpackChunkName: "component---src-pages-onze-diensten-js" */),
  "component---src-pages-over-ons-js": () => import("/opt/build/repo/src/pages/over-ons.js" /* webpackChunkName: "component---src-pages-over-ons-js" */),
  "component---src-pages-privacyverklaring-js": () => import("/opt/build/repo/src/pages/privacyverklaring.js" /* webpackChunkName: "component---src-pages-privacyverklaring-js" */),
  "component---src-pages-verstuurd-js": () => import("/opt/build/repo/src/pages/verstuurd.js" /* webpackChunkName: "component---src-pages-verstuurd-js" */)
}

